import React from "react"
import { handleLeadForm } from "../utils/globalState"

export default function CTABanner() {
  return (
    <section className="px-4 md:px-8 py-20">
      <div className="max-w-screen-xl mx-auto text-center flex flex-col items-center gap-6">
        <h4 className="text-lg md:text-3xl max-w-[44ch] mx-auto md:leading-normal">
          Scale your communication with enterprise grade VoIP.
        </h4>
        <button
          onClick={handleLeadForm}
          className="text-white text-base md:text-lg px-8 font-medium py-3 bg-accent rounded-full shadow-lg shadow-accent/20 hover:bg-blue-700 transition-all duration-200"
        >
          Contact Sales
        </button>
      </div>
    </section>
  )
}
