import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../components/Layout"
import CTABanner from "../components/CTABanner"
import Testimonial from "../components/Testimonial"
import Feature from "../components/Feature"
import LogoWall from "../components/LogoWall"
import { handleLeadForm } from "../utils/globalState"
import { motion } from "framer-motion"
import { Helmet } from "react-helmet"
import MetaImage from "../images/banner.png"

const metaImageUrl = `https://www.novanet.net${MetaImage}`

let pathname = typeof window !== "undefined" ? window.location.pathname : ""

export default function Homepage() {
  return (
    <Layout>
      <Helmet title="Novanet">
        <meta
          name="description"
          content="We have spent 15 years perfecting our VoIP technology & building one
            of the world’s most reliable telephony networks, which handles 10
            million calls per day."
        />
        <meta name="image" content={metaImageUrl} />
        <meta property="og:title" content={"Novanet"} />

        <meta property="og:image" content={metaImageUrl} />
        <meta name="twitter:title" content={"Novanet"} />
        <meta
          name="twitter:description"
          content="We have spent 15 years perfecting our VoIP technology & building one
          of the world’s most reliable telephony networks, which handles 10
          million calls per day"
        />
        <meta name="twitter:image" content={metaImageUrl} />
        <meta
          property="og:url"
          content={`https://www.novanet.net${pathname}/`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:type" content="website" />
      </Helmet>
      <section className="mt-16 px-4 md:px-8 py-16  md:py-28 relative bg-white">
        <article className="max-w-screen-xl relative z-10 mx-auto flex flex-col md:items-center justify-center gap-4 md:gap-8 md:text-center">
          <motion.h1
            initial={{ opacity: 0, y: 32 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ ease: "easeInOut", duration: 0.4 }}
            viewport={{ once: true }}
            className="text-2xl md:text-4xl lg:text-[72px] font-extrabold tracking-tight md:leading-[1.15]"
          >
            A Global{" "}
            <span className="hero-gradient-text">VoIP Powerhouse.</span>
            <br /> 15 Years in the Making.
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 32 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ ease: "easeInOut", duration: 0.4 }}
            viewport={{ once: true }}
            className="text-black font-light text-base md:text-xl md:leading-relaxed max-w-[64ch] mx-auto md:text-center"
          >
            We have spent 15 years perfecting our VoIP technology & building one
            of the world’s most reliable telephony networks, which handles 10
            million calls per day.
          </motion.p>
          <motion.button
            initial={{
              opacity: 0,
              y: 32,
              paddingLeft: 32,
              paddingRight: 32,
              paddingTop: 12,
              paddingBottom: 12,
            }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            whileTap={{ scale: 0.98 }}
            transition={{ ease: "easeInOut", duration: 0.4 }}
            onClick={handleLeadForm}
            className="text-white text-base md:text-lg px-8 font-medium py-3 bg-accent rounded-full shadow-lg shadow-accent/20 hover:bg-blue-700 "
          >
            Contact Sales
          </motion.button>
        </article>
      </section>

      <LogoWall />

      <Feature
        title={
          <>
            Keeping the World{" "}
            <span className="bg-gradient-to-r from-accent to-blue-600 bg-clip-text text-transparent">
              Connected
            </span>
          </>
        }
        alternateOrder
        withWave
        backgroundColor="bg-sky"
        subtitle="Novanet offers outbound calls to almost every functional number on
        the planet. Local, National and Toll-Free numbers in 100+
        countries."
        artwork={
          <div className="w-11/12 aspect-square bg-transparent">
            <StaticImage
              src="../images/globe.png"
              alt="Global Connectivity"
              placeholder="none"
              quality={100}
            />
          </div>
        }
      />

      <Feature
        title={
          <>
            100% Uptime, <br />
            <span className="bg-gradient-to-r from-accent to-blue-600 bg-clip-text text-transparent">
              100% of the Time
            </span>
          </>
        }
        subtitle=" Our multi-region cloud has no single point of failure, so your
        calls always go through, even when a data center faces an outage."
        artwork={
          <div className="w-11/12 aspect-square bg-transparent">
            <StaticImage
              src="../images/sla.png"
              alt="SLA"
              placeholder="none"
              quality={100}
            />
          </div>
        }
      />

      <Feature
        title={
          <>
            <span className="bg-gradient-to-r from-accent to-blue-600 bg-clip-text text-transparent">
              Max{" "}
            </span>{" "}
            Capacity
          </>
        }
        withWave
        alternateOrder
        backgroundColor="bg-sky"
        subtitle="There is no practical limit on concurrent calls. Our VoIP network
        is designed to support dialer traffic that most SIP trunking
        providers can’t handle."
        artwork={
          <div className="w-11/12 aspect-square bg-transparent">
            <StaticImage
              src="../images/capacity.png"
              alt="Max. Capacity"
              placeholder="none"
              quality={100}
            />
          </div>
        }
      />

      <Feature
        title={
          <>
            <span className="bg-gradient-to-r from-accent to-blue-600 bg-clip-text text-transparent">
              Optimized
            </span>{" "}
            for Online
          </>
        }
        subtitle="Our routing algorithm dynamically selects the ideal path to cope
            with latency and packet drops for a singularly crisp and jitter-free
            call experience."
        artwork={
          <div className="w-11/12 aspect-square bg-transparent">
            <StaticImage
              src="../images/routing.png"
              alt="Routing Algorithm"
              placeholder="none"
              quality={100}
            />
          </div>
        }
      />

      <Testimonial />

      <CTABanner />
    </Layout>
  )
}
