import React from "react"
import { setShowLeadForm } from "../utils/globalState"
import { ArrowIcon, WaveIcon } from "./Icons"
import { motion } from "framer-motion"

export default function Feature({
  title = "",
  subtitle = "",
  artwork = "",
  showCTA = true,
  backgroundColor = "bg-white",
  withWave = false,
  alternateOrder = false,
}) {
  const handleLeadForm = () => {
    setShowLeadForm()
  }

  return (
    <section
      className={`px-4 md:px-8 relative py-12 md:py-20 ${
        backgroundColor ? backgroundColor : "bg-white"
      }`}
    >
      {withWave && (
        <div className="hidden md:block  absolute top-0 inset-x-0 transform rotate-180">
          <WaveIcon />
        </div>
      )}
      <div className="max-w-screen-xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-20 items-center">
        <motion.div
          initial={{ opacity: 0, y: 32 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ ease: "easeInOut", duration: 0.4 }}
          viewport={{ once: true }}
          className={`${
            alternateOrder ? "lg:order-last" : "order-first"
          } flex items-center justify-center`}
        >
          {artwork}
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 24 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ ease: "easeInOut", duration: 0.4 }}
          viewport={{ once: true }}
          className={`gap-4 md:gap-6 flex flex-col items-start `}
        >
          <h2 className="text-2xl md:text-3xl lg:text-[40px] font-extrabold md:leading-tight lg:leading-tight">
            {title}
          </h2>
          <p className="text-base md:text-xl md:max-w-prose mx-auto md:leading-relaxed font-light">
            {subtitle}
          </p>
          {showCTA && (
            <button
              onClick={handleLeadForm}
              className="text-base md:text-lg font-medium flex items-center gap-1 text-accent hover:text-indigo-600 transition-all duration-200"
            >
              <span>Contact Sales</span>
              <ArrowIcon />
            </button>
          )}
        </motion.div>
      </div>
      {withWave && (
        <div className="hidden md:block absolute bottom-0 inset-x-0">
          <WaveIcon />
        </div>
      )}
    </section>
  )
}
