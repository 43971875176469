import React from "react"
import AvisLogo from "../images/customers/avis.svg"
import UHCLogo from "../images/customers/uhc.svg"
import TeleperformanceLogo from "../images/customers/teleperformance.svg"
import HGSLogo from "../images/customers/hgs.svg"
import SimpliLearnLogo from "../images/customers/simplilearn.svg"
import HCLLogo from "../images/customers/hcl.svg"
import { motion } from "framer-motion"

export default function LogoWall() {
  return (
    <motion.section
      className="px-4 md:px-8 pb-12 bg-white"
      initial={{ opacity: 0, y: 32 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ ease: "easeInOut", duration: 0.4 }}
    >
      <div className="flex flex-col gap-12 items-center justify-center max-w-screen-xl mx-auto text-center">
        <p className="hidden md:block text-xs font-medium uppercase tracking-wider text-slate/50">
          Trusted by amazing companies worldwide
        </p>
        <div className="grid gap-8 lg:gap-0 grid-cols-2 md:grid-cols-3 lg:grid-cols-6 place-content-center">
          <img src={AvisLogo} alt="Avis" />
          <img src={UHCLogo} alt="United Healthcare" />
          <img src={TeleperformanceLogo} alt="Teleperformance" />
          <img src={HGSLogo} alt="Hinduja" />
          <img src={SimpliLearnLogo} alt="Simplilearn" />
          <img src={HCLLogo} alt="HCL" />
        </div>
      </div>
    </motion.section>
  )
}
