import React from "react"
import { WaveIcon } from "./Icons"
import ConcentrixLogo from "../images/customers/concentrix.svg"
import { StaticImage } from "gatsby-plugin-image"

export default function Testimonial() {
  return (
    <section className="px-4 md:px-8 py-32 bg-sky relative">
      <div className="absolute top-0 inset-x-0 transform rotate-180">
        <WaveIcon />
      </div>
      <div className="max-w-screen-xl mx-auto text-center flex flex-col items-center gap-8 relative">
        <img src={ConcentrixLogo} width="160px" alt="Concentrix" />
        <h3 className="text-base md:text-2xl md:leading-relaxed italic max-w-prose mx-auto">
          "Novanet gave us the ability to speed up our processes. Our success
          has been driven by their outstanding customer service. Our project
          head Alex and his technical team have been extremely patient and have
          addressed our pain points with utmost proficiency."
        </h3>

        <div className="flex flex-col  items-center justify-center">
          <StaticImage
            src="../images/renilda.jpeg"
            className="w-10 h-10 rounded-full"
            placeholder="none"
          />
          <p className="text-base md:text-lg mt-2 mb-1 font-medium">
            Renilda Delrosario
          </p>
          <p className="text-sm text-slate">IT Analysts at Concentrix</p>
        </div>
      </div>
      <div className="absolute bottom-0 inset-x-0">
        <WaveIcon />
      </div>
    </section>
  )
}
